import React from "react"
import { Helmet } from "react-helmet"

const Head = props => {
  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <script src="https://cdn.optimizely.com/js/15784060578.js"></script>
      <title>{props?.title}</title>
      <meta name="description" content={props?.description}></meta>
      <meta
        name="image"
        content="https://images.ctfassets.net/w1ipbbcgm10q/2kjHGWS9PzU8j0zHDKB9UP/b56e8004b02a93c183f43adc5fa19f53/OG_Image_Kafka_Summit.png"
      />
      <meta name="title" property="og:title" content="Kafka Summit" />
      <meta
        name="image"
        property="og:image"
        content="https://images.ctfassets.net/w1ipbbcgm10q/2kjHGWS9PzU8j0zHDKB9UP/b56e8004b02a93c183f43adc5fa19f53/OG_Image_Kafka_Summit.png"
      />
      <meta
        name="description"
        property="og:description"
        content="Real-time stream processing at massive scale"
      />
      <meta
        name="keywords"
        content="apache kafka, kafka, kafka summit, apache, summit, event, events, kafka europe, kafka americas, kafka apac"
      ></meta>
    </Helmet>
  )
}

export default Head
