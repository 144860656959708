import React from "react"
import Gmail from "../images/gmail.png"
import Indeed from "../images/in.png"
import Twitter from "../images/twitter.png"
import Facebook from "../images/facebook.png"

const Footer = () => {
  const date = new Date()
  return (
    <div className="footer_main">
      <p className="footer_text">
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.confluent.io/legal/confluent-privacy-statement/"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.confluent.io/terms-of-use/?csrfToken=1eb50ed7d7283abe11fd51eebed13e22&touchpointType=page&cmsPage=1"
          >
            Terms & Conditions
          </a>
        </span>
        ,<br /> Apache, Apache Kafka, Kafka, Apache Flink, Flink and associated
        open source project names are trademarks of the{" "}
        <a href="https://www.apache.org/" target="_blank">
          Apache Software Foundation
        </a>
        .<br /> The Apache Software Foundation has no affiliation with and does
        not endorse, or review the materials provided at this event
        <br /> Copyright © Confluent, Inc. 2016 - {date.getFullYear()}
      </p>
      <div className="footer_socialicon">
        <div className="footer_socialicon_text">
          <a target="_blank" href="https://twitter.com/hashtag/KafkaSummit">
            #kafkasummit
          </a>{" "}
        </div>
        <div className="footer_socialicon_icon">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/confluent/mycompany/"
          >
            <img src={Indeed} alt="confluent-logo" />
          </a>{" "}
        </div>
        <div className="footer_socialicon_icon">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/ConfluentInc"
          >
            <img src={Twitter} alt="twitter-logo" />
          </a>{" "}
        </div>
        <div className="footer_socialicon_icon">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/confluentinc/"
          >
            <img src={Facebook} alt="facebook-logo" />
          </a>{" "}
        </div>
        <div className="footer_socialicon_icon">
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:organizers@kafka-summit.org"
          >
            <img src={Gmail} alt="mail-logo" />
          </a>{" "}
        </div>
      </div>
    </div>
  )
}

export default Footer
